import { h, VNode } from 'snabbdom';

export const spinnerHtml =
    '<div class="spinner" aria-label="loading"><svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 200 200\'><circle fill=\'none\' stroke-opacity=\'1\' stroke=\'#5C84B1\' stroke-width=\'.5\' cx=\'100\' cy=\'100\' r=\'0\'><animate attributeName=\'r\' calcMode=\'spline\' dur=\'2\' values=\'1;80\' keyTimes=\'0;1\' keySplines=\'0 .2 .5 1\' repeatCount=\'indefinite\'></animate><animate attributeName=\'stroke-width\' calcMode=\'spline\' dur=\'2\' values=\'0;25\' keyTimes=\'0;1\' keySplines=\'0 .2 .5 1\' repeatCount=\'indefinite\'></animate><animate attributeName=\'stroke-opacity\' calcMode=\'spline\' dur=\'2\' values=\'1;0\' keyTimes=\'0;1\' keySplines=\'0 .2 .5 1\' repeatCount=\'indefinite\'></animate></circle></svg></div>\n'

// const pathAttrs = [
//     {
//         fill: 'none',
//         stroke: '#5C84B1',
//         'stroke-width': 15,
//         'stroke-linecap': 'round',
//         'stroke-dasharray': '300 385',  // Visible and invisible dash pattern
//         'stroke-dashoffset': 0,
//         d: 'M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z',
//     },
// ];

export const spinnerVdom = (box = '0 0 200 200'): VNode =>
    h('div.spinner', { 'aria-label': 'loading' }, [
        h('svg', { attrs: { xmlns: 'http://www.w3.org/2000/svg', viewBox: box } }, [
            h('circle', {
                attrs: {
                    fill: 'none',
                    'stroke-opacity': '1',
                    stroke: '#5C84B1',
                    'stroke-width': '.5',
                    cx: '100',
                    cy: '100',
                    r: '0',
                },
            }, [
                h('animate', {
                    attrs: {
                        attributeName: 'r',
                        calcMode: 'spline',
                        dur: '2',
                        values: '1;80',
                        keyTimes: '0;1',
                        keySplines: '0 .2 .5 1',
                        repeatCount: 'indefinite',
                    },
                }),
                h('animate', {
                    attrs: {
                        attributeName: 'stroke-width',
                        calcMode: 'spline',
                        dur: '2',
                        values: '0;25',
                        keyTimes: '0;1',
                        keySplines: '0 .2 .5 1',
                        repeatCount: 'indefinite',
                    },
                }),
                h('animate', {
                    attrs: {
                        attributeName: 'stroke-opacity',
                        calcMode: 'spline',
                        dur: '2',
                        values: '1;0',
                        keyTimes: '0;1',
                        keySplines: '0 .2 .5 1',
                        repeatCount: 'indefinite',
                    },
                }),
            ]),
        ]),
    ]);



export const stockfishName = 'Stockfish 16.1';

export const chartSpinner = (): VNode =>
    h('div#acpl-chart-container-loader', [
        h('span', [stockfishName, h('br'), 'Server analysis']),
        spinnerVdom(),
    ]);
